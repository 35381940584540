
  <template>
    <div class="nui-doc">
      <h1>Grid System 栅格系统</h1>
<p>参考了 <a href="https://getbootstrap.com/docs/5.0/layout/grid/">bootstrap 的 12 栅格系统</a>，NUI 借助 CSS Flex 属性设计了一套 20 栅格系统。</p>
<div class="badge badge-tip"><p class="badge-title">为什么选择 20 而不是其他数字（例如 BootStrap 的 12）？</p>
<p>20 能被 5 、10 约分，简化设计人员的计算过程。20 也是 100 的公约数，在 CSS 样式中，也能保证不会出现小数点除不尽的现象。</p>
<p>20 这个数字也足够大，使栅格颗粒度足够精细。</p>
</div>
<h2>Row 属性</h2>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>tab</td>
<td>渲染出的实际标签</td>
<td>String</td>
<td>-</td>
<td>div</td>
</tr>
<tr>
<td>justify</td>
<td>子元素的水平对齐方式</td>
<td>String</td>
<td>flex-start/flex-end/center/space-around/space-between</td>
<td>flex-start</td>
</tr>
<tr>
<td>align</td>
<td>子元素的垂直对齐方式</td>
<td>String</td>
<td>flex-start/flex-end/center/baseline/stretch</td>
<td>flex-start</td>
</tr>
<tr>
<td>gutter</td>
<td>子元素间的间隔</td>
<td>Number</td>
<td>（0,+♾️)</td>
<td>-</td>
</tr>
</tbody>
</table>
<h2>Col 属性</h2>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>tab</td>
<td>渲染出的实际标签</td>
<td>String</td>
<td>-</td>
<td>div</td>
</tr>
<tr>
<td>span</td>
<td>栅格占据的列数</td>
<td>Number</td>
<td>(0,20]</td>
<td>20</td>
</tr>
<tr>
<td>xs/sm/md/lg/xl</td>
<td>在不同屏幕宽度下，占用的列数</td>
<td>Number</td>
<td>(0,20]</td>
<td>-</td>
</tr>
</tbody>
</table>
<h2>响应式</h2>
<table>
<thead>
<tr>
<th>名称</th>
<th>定义</th>
</tr>
</thead>
<tbody>
<tr>
<td>xs</td>
<td>&lt;768px</td>
</tr>
<tr>
<td>sm</td>
<td>≥768px</td>
</tr>
<tr>
<td>md</td>
<td>≥992px</td>
</tr>
<tr>
<td>lg</td>
<td>≥1200px</td>
</tr>
<tr>
<td>xl</td>
<td>≥1920px</td>
</tr>
</tbody>
</table>
<h2>基础使用</h2>
<nui-demo>
          <template v-slot:des><p>通过 row 和 col 组件，并通过 col 组件的 `span` 属性我们就可以自由地组合布局。</p></template>
          <template v-slot:show><Nuidemohgridsystedivclassrowbgnrownco1796 /></template>
          <template v-slot:code>
        <pre class="hljs" v-pre><code><span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;row-bg&quot;</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">n-row</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">&quot;20&quot;</span>&gt;</span><span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;grid-content bg-purple-dark&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">n-col</span>&gt;</span>
  <span class="hljs-tag">&lt;/<span class="hljs-name">n-row</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">n-row</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">&quot;10&quot;</span>&gt;</span><span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;grid-content bg-purple&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">n-col</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">&quot;10&quot;</span>&gt;</span><span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;grid-content bg-purple-dark&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">n-col</span>&gt;</span>
  <span class="hljs-tag">&lt;/<span class="hljs-name">n-row</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">n-row</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">&quot;5&quot;</span>&gt;</span><span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;grid-content bg-purple-dark&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">n-col</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">&quot;5&quot;</span>&gt;</span><span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;grid-content bg-purple&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">n-col</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">&quot;5&quot;</span>&gt;</span><span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;grid-content bg-purple-dark&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">n-col</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">&quot;5&quot;</span>&gt;</span><span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;grid-content bg-purple&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">n-col</span>&gt;</span>
  <span class="hljs-tag">&lt;/<span class="hljs-name">n-row</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">n-row</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">&quot;4&quot;</span>&gt;</span><span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;grid-content bg-purple&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">n-col</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">&quot;4&quot;</span>&gt;</span><span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;grid-content bg-purple-dark&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">n-col</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">&quot;4&quot;</span>&gt;</span><span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;grid-content bg-purple&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">n-col</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">&quot;4&quot;</span>&gt;</span><span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;grid-content bg-purple-dark&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">n-col</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">&quot;4&quot;</span>&gt;</span><span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;grid-content bg-purple&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">n-col</span>&gt;</span>
  <span class="hljs-tag">&lt;/<span class="hljs-name">n-row</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">n-row</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">&quot;2&quot;</span>&gt;</span><span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;grid-content bg-purple-dark&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">n-col</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">&quot;2&quot;</span>&gt;</span><span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;grid-content bg-purple&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">n-col</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">&quot;2&quot;</span>&gt;</span><span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;grid-content bg-purple-dark&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">n-col</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">&quot;2&quot;</span>&gt;</span><span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;grid-content bg-purple&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">n-col</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">&quot;2&quot;</span>&gt;</span><span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;grid-content bg-purple-dark&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">n-col</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">&quot;2&quot;</span>&gt;</span><span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;grid-content bg-purple&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">n-col</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">&quot;2&quot;</span>&gt;</span><span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;grid-content bg-purple-dark&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">n-col</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">&quot;2&quot;</span>&gt;</span><span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;grid-content bg-purple&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">n-col</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">&quot;2&quot;</span>&gt;</span><span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;grid-content bg-purple-dark&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">n-col</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">&quot;2&quot;</span>&gt;</span><span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;grid-content bg-purple&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">n-col</span>&gt;</span>
  <span class="hljs-tag">&lt;/<span class="hljs-name">n-row</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span>

<span class="hljs-tag">&lt;<span class="hljs-name">style</span> <span class="hljs-attr">scoped</span>&gt;</span><span class="css">
  <span class="hljs-selector-class">.n-row</span> {
    <span class="hljs-attribute">margin-bottom</span>: <span class="hljs-number">20px</span>;
    &amp;<span class="hljs-selector-pseudo">:last-child</span> {
      <span class="hljs-attribute">margin-bottom</span>: <span class="hljs-number">0</span>;
    }
  }
  <span class="hljs-selector-class">.n-col</span> {
    <span class="hljs-attribute">border-radius</span>: <span class="hljs-number">4px</span>;
  }
  <span class="hljs-selector-class">.bg-purple-dark</span> {
    <span class="hljs-attribute">background</span>: <span class="hljs-number">#99a9bf</span>;
  }
  <span class="hljs-selector-class">.bg-purple</span> {
    <span class="hljs-attribute">background</span>: <span class="hljs-number">#d3dce6</span>;
  }
  <span class="hljs-selector-class">.grid-content</span> {
    <span class="hljs-attribute">border-radius</span>: <span class="hljs-number">4px</span>;
    <span class="hljs-attribute">min-height</span>: <span class="hljs-number">36px</span>;
  }
  <span class="hljs-selector-class">.row-bg</span> {
    <span class="hljs-attribute">padding</span>: <span class="hljs-number">10px</span>;
    <span class="hljs-attribute">background-color</span>: <span class="hljs-number">#f9fafc</span>;
  }
</span><span class="hljs-tag">&lt;/<span class="hljs-name">style</span>&gt;</span>
</code></pre>
</template></nui-demo>
<h2>控制间隔</h2>
<nui-demo>
          <template v-slot:des><p>row 使用 gutter 属性控制间隔</p></template>
          <template v-slot:show><Nuidemohgridsystedivclassrowbgnrowgut17488 /></template>
          <template v-slot:code>
        <pre class="hljs" v-pre><code><span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;row-bg&quot;</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">n-row</span> <span class="hljs-attr">:gutter</span>=<span class="hljs-string">&quot;10&quot;</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">&quot;5&quot;</span>&gt;</span><span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;grid-content bg-purple-dark&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">n-col</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">&quot;5&quot;</span>&gt;</span><span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;grid-content bg-purple&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">n-col</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">&quot;5&quot;</span>&gt;</span><span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;grid-content bg-purple-dark&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">n-col</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">&quot;5&quot;</span>&gt;</span><span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;grid-content bg-purple&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">n-col</span>&gt;</span>
  <span class="hljs-tag">&lt;/<span class="hljs-name">n-row</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">n-row</span> <span class="hljs-attr">:gutter</span>=<span class="hljs-string">&quot;20&quot;</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">&quot;5&quot;</span>&gt;</span><span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;grid-content bg-purple&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">n-col</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">&quot;5&quot;</span>&gt;</span><span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;grid-content bg-purple-dark&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">n-col</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">&quot;5&quot;</span>&gt;</span><span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;grid-content bg-purple&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">n-col</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">&quot;5&quot;</span>&gt;</span><span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;grid-content bg-purple-dark&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">n-col</span>&gt;</span>
  <span class="hljs-tag">&lt;/<span class="hljs-name">n-row</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">n-row</span> <span class="hljs-attr">:gutter</span>=<span class="hljs-string">&quot;40&quot;</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">&quot;5&quot;</span>&gt;</span><span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;grid-content bg-purple-dark&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">n-col</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">&quot;5&quot;</span>&gt;</span><span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;grid-content bg-purple&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">n-col</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">&quot;5&quot;</span>&gt;</span><span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;grid-content bg-purple-dark&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">n-col</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">&quot;5&quot;</span>&gt;</span><span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;grid-content bg-purple&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">n-col</span>&gt;</span>
  <span class="hljs-tag">&lt;/<span class="hljs-name">n-row</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span>

<span class="hljs-tag">&lt;<span class="hljs-name">style</span> <span class="hljs-attr">scoped</span>&gt;</span><span class="css">
  <span class="hljs-selector-class">.n-row</span> {
    <span class="hljs-attribute">margin-bottom</span>: <span class="hljs-number">20px</span>;
    &amp;<span class="hljs-selector-pseudo">:last-child</span> {
      <span class="hljs-attribute">margin-bottom</span>: <span class="hljs-number">0</span>;
    }
  }
  <span class="hljs-selector-class">.n-col</span> {
    <span class="hljs-attribute">border-radius</span>: <span class="hljs-number">4px</span>;
  }
  <span class="hljs-selector-class">.bg-purple-dark</span> {
    <span class="hljs-attribute">background</span>: <span class="hljs-number">#99a9bf</span>;
  }
  <span class="hljs-selector-class">.bg-purple</span> {
    <span class="hljs-attribute">background</span>: <span class="hljs-number">#d3dce6</span>;
  }
  <span class="hljs-selector-class">.grid-content</span> {
    <span class="hljs-attribute">border-radius</span>: <span class="hljs-number">4px</span>;
    <span class="hljs-attribute">min-height</span>: <span class="hljs-number">36px</span>;
  }
  <span class="hljs-selector-class">.row-bg</span> {
    <span class="hljs-attribute">padding</span>: <span class="hljs-number">10px</span>;
    <span class="hljs-attribute">background-color</span>: <span class="hljs-number">#f9fafc</span>;
  }
</span><span class="hljs-tag">&lt;/<span class="hljs-name">style</span>&gt;</span>
</code></pre>
</template></nui-demo>
<h2>断点</h2>
<nui-demo>
          
          <template v-slot:show><Nuidemohgridsystenrowncolspanxssmmdlg27411 /></template>
          <template v-slot:code>
        <pre class="hljs" v-pre><code><span class="hljs-tag">&lt;<span class="hljs-name">n-row</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">n-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">&quot;20&quot;</span> <span class="hljs-attr">:xs</span>=<span class="hljs-string">&quot;20&quot;</span> <span class="hljs-attr">:sm</span>=<span class="hljs-string">&quot;15&quot;</span> <span class="hljs-attr">:md</span>=<span class="hljs-string">&quot;10&quot;</span> <span class="hljs-attr">:lg</span>=<span class="hljs-string">&quot;5&quot;</span> <span class="hljs-attr">:xl</span>=<span class="hljs-string">&quot;1&quot;</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;bg-red&quot;</span>&gt;</span>HI<span class="hljs-tag">&lt;/<span class="hljs-name">n-col</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">n-row</span>&gt;</span>
</code></pre>
</template></nui-demo>

    </div>
  </template>
  