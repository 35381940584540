<template>
  <div class="test">
    <h1>This is a test page</h1>
    <n-example></n-example>
    <n-card>
      <n-card-title>Title 标题文本色</n-card-title>
      <n-card-subtitle>Subtitle 副标题文本色</n-card-subtitle>
      <n-card-text>Text 正文文本色</n-card-text>
      <n-card-action>
        <n-button color="primary">Primary</n-button>
        <n-button color="success">success</n-button>
        <n-button color="warning">warning</n-button>
        <n-button color="danger">danger</n-button>
        <n-button color="info">default / info</n-button>
      </n-card-action>
    </n-card>
  </div>
</template>