
  <template>
    <div class="nui-doc">
      <h1>Style 样式</h1>
<h2>内边距、外边距</h2>
<p>辅助类应用到元素的 <strong>margin</strong> 或 <strong>padding</strong> 范围是从 0 到 16. 这些类可以通过 <code>{property}{direction}-{size}</code> 格式使用。</p>
<p><strong>property</strong> 应用间距类型:</p>
<ul>
<li><code>m</code> - 应用 <code>margin</code></li>
<li><code>p</code> - 应用 <code>padding</code></li>
</ul>
<p><strong>direction</strong> 指定了该属性所应用的侧边:</p>
<ul>
<li><code>t</code> - 应用 <code>margin-top</code> 和 <code>padding-top</code> 的间距</li>
<li><code>b</code> - 应用 <code>margin-bottom</code> 和 <code>padding-bottom</code> 的间距</li>
<li><code>l</code> - 应用 <code>margin-left</code> 和 <code>padding-left</code> 的间距</li>
<li><code>t</code> - 应用 <code>margin-right</code> 和 <code>padding-right</code> 的间距</li>
<li><code>x</code> - 应用 <code>*-left</code> 和 <code>*-right</code> 的间距</li>
<li><code>y</code> - 应用 <code>*-top</code> 和 <code>*-bottom</code> 的间距</li>
<li><code>a</code> - 在所有方向应用该间距</li>
</ul>
<p><strong>size</strong> 以 4px 为步长，取值为 [0,20].</p>

    </div>
  </template>
  