
  <template>
    <div class="nui-doc">
      <h1>Link 链接</h1>
<p>文字超链接。</p>
<p>接受 HTML 中 a 元素的全部属性。</p>
<h2>基础使用</h2>
<nui-demo>
          
          <template v-slot:show><Nuidemohlinkhppphnlinkhrefhttpsnuilib105 /></template>
          <template v-slot:code>
        <pre class="hljs" v-pre><code><span class="hljs-tag">&lt;<span class="hljs-name">n-link</span> <span class="hljs-attr">href</span>=<span class="hljs-string">&quot;https://nuilibrary.online/&quot;</span> <span class="hljs-attr">target</span>=<span class="hljs-string">&quot;_blank&quot;</span>&gt;</span>NUI<span class="hljs-tag">&lt;/<span class="hljs-name">n-link</span>&gt;</span> |
<span class="hljs-tag">&lt;<span class="hljs-name">n-link</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;primary&quot;</span>&gt;</span>NUI<span class="hljs-tag">&lt;/<span class="hljs-name">n-link</span>&gt;</span> |
<span class="hljs-tag">&lt;<span class="hljs-name">n-link</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;success&quot;</span>&gt;</span>NUI<span class="hljs-tag">&lt;/<span class="hljs-name">n-link</span>&gt;</span> |
<span class="hljs-tag">&lt;<span class="hljs-name">n-link</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;warning&quot;</span>&gt;</span>NUI<span class="hljs-tag">&lt;/<span class="hljs-name">n-link</span>&gt;</span> |
<span class="hljs-tag">&lt;<span class="hljs-name">n-link</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;danger&quot;</span>&gt;</span>NUI<span class="hljs-tag">&lt;/<span class="hljs-name">n-link</span>&gt;</span> |
<span class="hljs-tag">&lt;<span class="hljs-name">n-link</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;info&quot;</span>&gt;</span>NUI<span class="hljs-tag">&lt;/<span class="hljs-name">n-link</span>&gt;</span>
</code></pre>
</template></nui-demo>
<h2>无下划线</h2>
<p>使用<code>underline</code>属性控制悬浮时，是否出现下划线。</p>
<p>当使用<code>disabled</code>属性时，无论<code>underline</code>如何，都不会出现下划线。</p>
<nui-demo>
          
          <template v-slot:show><Nuidemohlinkhppphnlinkhrefhttpsnuilib2261 /></template>
          <template v-slot:code>
        <pre class="hljs" v-pre><code><span class="hljs-tag">&lt;<span class="hljs-name">n-link</span> <span class="hljs-attr">href</span>=<span class="hljs-string">&quot;https://nuilibrary.online/&quot;</span> <span class="hljs-attr">target</span>=<span class="hljs-string">&quot;_blank&quot;</span> <span class="hljs-attr">nounderline</span>&gt;</span>NUI<span class="hljs-tag">&lt;/<span class="hljs-name">n-link</span>&gt;</span> |
<span class="hljs-tag">&lt;<span class="hljs-name">n-link</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;primary&quot;</span> <span class="hljs-attr">nounderline</span>&gt;</span>NUI<span class="hljs-tag">&lt;/<span class="hljs-name">n-link</span>&gt;</span> |
<span class="hljs-tag">&lt;<span class="hljs-name">n-link</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;success&quot;</span> <span class="hljs-attr">nounderline</span>&gt;</span>NUI<span class="hljs-tag">&lt;/<span class="hljs-name">n-link</span>&gt;</span> |
<span class="hljs-tag">&lt;<span class="hljs-name">n-link</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;warning&quot;</span> <span class="hljs-attr">nounderline</span>&gt;</span>NUI<span class="hljs-tag">&lt;/<span class="hljs-name">n-link</span>&gt;</span> |
<span class="hljs-tag">&lt;<span class="hljs-name">n-link</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;danger&quot;</span> <span class="hljs-attr">nounderline</span>&gt;</span>NUI<span class="hljs-tag">&lt;/<span class="hljs-name">n-link</span>&gt;</span> |
<span class="hljs-tag">&lt;<span class="hljs-name">n-link</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;info&quot;</span> <span class="hljs-attr">nounderline</span>&gt;</span>NUI<span class="hljs-tag">&lt;/<span class="hljs-name">n-link</span>&gt;</span>
</code></pre>
</template></nui-demo>
<h2>禁用</h2>
<p>使用<code>disabled</code>属性来控制是否禁用。</p>
<p>当使用<code>disabled</code>属性时，无论<code>underline</code>如何，都不会出现下划线。</p>
<nui-demo>
          
          <template v-slot:show><Nuidemohlinkhppphnlinkhrefhttpsnuilib4738 /></template>
          <template v-slot:code>
        <pre class="hljs" v-pre><code><span class="hljs-tag">&lt;<span class="hljs-name">n-link</span> <span class="hljs-attr">href</span>=<span class="hljs-string">&quot;https://nuilibrary.online/&quot;</span> <span class="hljs-attr">target</span>=<span class="hljs-string">&quot;_blank&quot;</span> <span class="hljs-attr">disabled</span>&gt;</span>NUI<span class="hljs-tag">&lt;/<span class="hljs-name">n-link</span>&gt;</span> |
<span class="hljs-tag">&lt;<span class="hljs-name">n-link</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;primary&quot;</span> <span class="hljs-attr">disabled</span>&gt;</span>NUI<span class="hljs-tag">&lt;/<span class="hljs-name">n-link</span>&gt;</span> |
<span class="hljs-tag">&lt;<span class="hljs-name">n-link</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;success&quot;</span> <span class="hljs-attr">disabled</span>&gt;</span>NUI<span class="hljs-tag">&lt;/<span class="hljs-name">n-link</span>&gt;</span> |
<span class="hljs-tag">&lt;<span class="hljs-name">n-link</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;warning&quot;</span> <span class="hljs-attr">disabled</span>&gt;</span>NUI<span class="hljs-tag">&lt;/<span class="hljs-name">n-link</span>&gt;</span> |
<span class="hljs-tag">&lt;<span class="hljs-name">n-link</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;danger&quot;</span> <span class="hljs-attr">disabled</span>&gt;</span>NUI<span class="hljs-tag">&lt;/<span class="hljs-name">n-link</span>&gt;</span> |
<span class="hljs-tag">&lt;<span class="hljs-name">n-link</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;info&quot;</span> <span class="hljs-attr">disabled</span>&gt;</span>NUI<span class="hljs-tag">&lt;/<span class="hljs-name">n-link</span>&gt;</span>
</code></pre>
</template></nui-demo>

    </div>
  </template>
  