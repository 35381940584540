
  <template>
    <div class="nui-doc">
      <h1>Introduction 介绍</h1>
<h2>NUI 是什么</h2>
<p>NUI 是一个基于 Vue 3 的 UI 组件库。</p>
<h2>NUI 有什么不同之处</h2>
<p>NUI 是一个高可定制的 UI 组件库，将 UI 设计的权利交付到下游。</p>

    </div>
  </template>
  