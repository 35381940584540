
  <template>
    <div class="nui-doc">
      <h1>Button 按钮</h1>
<h2>基础用法</h2>
<nui-demo>
          <template v-slot:des><p>基础使用</p></template>
          <template v-slot:show><Nuidemohbuttonhhhnbuttonnbuttonnbutto108 /></template>
          <template v-slot:code>
        <pre class="hljs" v-pre><code><span class="hljs-tag">&lt;<span class="hljs-name">n-button</span>&gt;</span>默认按钮<span class="hljs-tag">&lt;/<span class="hljs-name">n-button</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">n-button</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;primary&quot;</span>&gt;</span>主题按钮<span class="hljs-tag">&lt;/<span class="hljs-name">n-button</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">n-button</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;success&quot;</span>&gt;</span>成功按钮<span class="hljs-tag">&lt;/<span class="hljs-name">n-button</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">n-button</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;warning&quot;</span>&gt;</span>警告按钮<span class="hljs-tag">&lt;/<span class="hljs-name">n-button</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">n-button</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;danger&quot;</span>&gt;</span>警告按钮<span class="hljs-tag">&lt;/<span class="hljs-name">n-button</span>&gt;</span>
</code></pre>
</template></nui-demo>
<h2>丰富的样式（朴素/圆角/禁用）</h2>
<nui-demo>
          
          <template v-slot:show><Nuidemohbuttonhhhnbuttonplainnbuttonn1661 /></template>
          <template v-slot:code>
        <pre class="hljs" v-pre><code><span class="hljs-tag">&lt;<span class="hljs-name">n-button</span> <span class="hljs-attr">plain</span>&gt;</span>朴素按钮<span class="hljs-tag">&lt;/<span class="hljs-name">n-button</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">n-button</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;primary&quot;</span> <span class="hljs-attr">plain</span>&gt;</span>主题按钮<span class="hljs-tag">&lt;/<span class="hljs-name">n-button</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">n-button</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;success&quot;</span> <span class="hljs-attr">plain</span>&gt;</span>成功按钮<span class="hljs-tag">&lt;/<span class="hljs-name">n-button</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">n-button</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;warning&quot;</span> <span class="hljs-attr">plain</span>&gt;</span>警告按钮<span class="hljs-tag">&lt;/<span class="hljs-name">n-button</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">n-button</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;danger&quot;</span> <span class="hljs-attr">plain</span>&gt;</span>危险按钮<span class="hljs-tag">&lt;/<span class="hljs-name">n-button</span>&gt;</span>

<span class="hljs-tag">&lt;<span class="hljs-name">br</span>/&gt;</span>

<span class="hljs-tag">&lt;<span class="hljs-name">n-button</span> <span class="hljs-attr">round</span>&gt;</span>圆角按钮<span class="hljs-tag">&lt;/<span class="hljs-name">n-button</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">n-button</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;primary&quot;</span> <span class="hljs-attr">round</span>&gt;</span>主题按钮<span class="hljs-tag">&lt;/<span class="hljs-name">n-button</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">n-button</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;success&quot;</span> <span class="hljs-attr">round</span>&gt;</span>成功按钮<span class="hljs-tag">&lt;/<span class="hljs-name">n-button</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">n-button</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;warning&quot;</span> <span class="hljs-attr">round</span>&gt;</span>警告按钮<span class="hljs-tag">&lt;/<span class="hljs-name">n-button</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">n-button</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;danger&quot;</span> <span class="hljs-attr">round</span>&gt;</span>危险按钮<span class="hljs-tag">&lt;/<span class="hljs-name">n-button</span>&gt;</span>

<span class="hljs-tag">&lt;<span class="hljs-name">br</span>/&gt;</span>

<span class="hljs-tag">&lt;<span class="hljs-name">n-button</span> <span class="hljs-attr">disabled</span>&gt;</span>禁用按钮<span class="hljs-tag">&lt;/<span class="hljs-name">n-button</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">n-button</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;primary&quot;</span> <span class="hljs-attr">disabled</span>&gt;</span>主题按钮<span class="hljs-tag">&lt;/<span class="hljs-name">n-button</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">n-button</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;success&quot;</span> <span class="hljs-attr">disabled</span>&gt;</span>成功按钮<span class="hljs-tag">&lt;/<span class="hljs-name">n-button</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">n-button</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;warning&quot;</span> <span class="hljs-attr">disabled</span>&gt;</span>警告按钮<span class="hljs-tag">&lt;/<span class="hljs-name">n-button</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">n-button</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;danger&quot;</span> <span class="hljs-attr">disabled</span>&gt;</span>危险按钮<span class="hljs-tag">&lt;/<span class="hljs-name">n-button</span>&gt;</span>

<span class="hljs-tag">&lt;<span class="hljs-name">br</span>/&gt;</span>

<span class="hljs-tag">&lt;<span class="hljs-name">n-button</span> <span class="hljs-attr">plain</span> <span class="hljs-attr">disabled</span>&gt;</span>混合样式<span class="hljs-tag">&lt;/<span class="hljs-name">n-button</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">n-button</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;primary&quot;</span> <span class="hljs-attr">plain</span> <span class="hljs-attr">round</span>&gt;</span>主题按钮<span class="hljs-tag">&lt;/<span class="hljs-name">n-button</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">n-button</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;success&quot;</span> <span class="hljs-attr">plain</span> <span class="hljs-attr">round</span> &gt;</span>成功按钮<span class="hljs-tag">&lt;/<span class="hljs-name">n-button</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">n-button</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;warning&quot;</span> <span class="hljs-attr">round</span> <span class="hljs-attr">disabled</span>&gt;</span>警告按钮<span class="hljs-tag">&lt;/<span class="hljs-name">n-button</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">n-button</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;danger&quot;</span> <span class="hljs-attr">round</span> <span class="hljs-attr">plain</span> <span class="hljs-attr">disabled</span>&gt;</span>危险按钮<span class="hljs-tag">&lt;/<span class="hljs-name">n-button</span>&gt;</span>
</code></pre>
</template></nui-demo>
<h2>尺寸</h2>
<nui-demo>
          
          <template v-slot:show><Nuidemohbuttonhhhnbuttonsizelglgnbutt8674 /></template>
          <template v-slot:code>
        <pre class="hljs" v-pre><code><span class="hljs-tag">&lt;<span class="hljs-name">n-button</span> <span class="hljs-attr">size</span>=<span class="hljs-string">&quot;lg&quot;</span>&gt;</span>LG 大号按钮<span class="hljs-tag">&lt;/<span class="hljs-name">n-button</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">n-button</span>&gt;</span>MD 默认按钮<span class="hljs-tag">&lt;/<span class="hljs-name">n-button</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">n-button</span> <span class="hljs-attr">size</span>=<span class="hljs-string">&quot;sm&quot;</span>&gt;</span>SM 小号按钮<span class="hljs-tag">&lt;/<span class="hljs-name">n-button</span>&gt;</span>
</code></pre>
</template></nui-demo>
<h2>超链接按钮</h2>
<p>当使用了<code>helf</code>属性时，按钮将变成超链接，可选<code>target</code>属性。
<code>helf</code>与<code>target</code>与 HTML 中 a 标签中的用法保持一致。</p>
<h2>属性</h2>

    </div>
  </template>
  