
  <template>
    <div class="nui-doc">
      <h1>Icon 图标 <n-icon>I</n-icon><n-icon>C</n-icon><n-icon>O</n-icon><n-icon>N</n-icon></h1>
<p>NUI 借助 IconFont 网站，积累了一套 Icon 库。图标格式为可缩放矢量图形（Scalable Vector Graphics，<em>SVG</em>），实际使用与普通文字并无差别，大小、颜色等文本具备的属性 Icon 组件均具备。</p>
<nui-demo>
          
          <template v-slot:show><Nuidemohiconniconpnicongooglenicongoo253 /></template>
          <template v-slot:code>
        <pre class="hljs" v-pre><code><span class="hljs-tag">&lt;<span class="hljs-name">p</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">n-icon</span>&gt;</span>google<span class="hljs-tag">&lt;/<span class="hljs-name">n-icon</span>&gt;</span>Google
	<span class="hljs-tag">&lt;<span class="hljs-name">n-icon</span>&gt;</span>apple-fill<span class="hljs-tag">&lt;/<span class="hljs-name">n-icon</span>&gt;</span>Apple
	<span class="hljs-tag">&lt;<span class="hljs-name">n-icon</span> <span class="hljs-attr">i</span>=<span class="hljs-string">&quot;github-fill&quot;</span>/&gt;</span>Github
<span class="hljs-tag">&lt;/<span class="hljs-name">p</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">p</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;text-sky&quot;</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">n-icon</span>&gt;</span>google<span class="hljs-tag">&lt;/<span class="hljs-name">n-icon</span>&gt;</span>Google
	<span class="hljs-tag">&lt;<span class="hljs-name">n-icon</span>&gt;</span>apple-fill<span class="hljs-tag">&lt;/<span class="hljs-name">n-icon</span>&gt;</span>Apple
	<span class="hljs-tag">&lt;<span class="hljs-name">n-icon</span> <span class="hljs-attr">i</span>=<span class="hljs-string">&quot;github-fill&quot;</span>/&gt;</span>Github
<span class="hljs-tag">&lt;/<span class="hljs-name">p</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">p</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;text-danger&quot;</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">n-icon</span>&gt;</span>google<span class="hljs-tag">&lt;/<span class="hljs-name">n-icon</span>&gt;</span>Google
	<span class="hljs-tag">&lt;<span class="hljs-name">n-icon</span>&gt;</span>apple-fill<span class="hljs-tag">&lt;/<span class="hljs-name">n-icon</span>&gt;</span>Apple
	<span class="hljs-tag">&lt;<span class="hljs-name">n-icon</span> <span class="hljs-attr">i</span>=<span class="hljs-string">&quot;github-fill&quot;</span>/&gt;</span>Github
<span class="hljs-tag">&lt;/<span class="hljs-name">p</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">h1</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">n-icon</span>&gt;</span>google<span class="hljs-tag">&lt;/<span class="hljs-name">n-icon</span>&gt;</span>Google
	<span class="hljs-tag">&lt;<span class="hljs-name">n-icon</span>&gt;</span>apple-fill<span class="hljs-tag">&lt;/<span class="hljs-name">n-icon</span>&gt;</span>Apple
	<span class="hljs-tag">&lt;<span class="hljs-name">n-icon</span> <span class="hljs-attr">i</span>=<span class="hljs-string">&quot;github-fill&quot;</span>/&gt;</span>Github
<span class="hljs-tag">&lt;/<span class="hljs-name">h1</span>&gt;</span>
</code></pre>
</template></nui-demo>

    </div>
  </template>
  