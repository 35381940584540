
  <template>
    <div class="nui-doc">
      <h1>Application 应用程序</h1>
<p>NUI 归纳了常用的应用程序布局方式，可分为以下 6 种。</p>
<p>[图片]</p>
<p>通过<code>AppBar</code>、<code>AppMain</code>、<code>AppNav</code>、<code>AppFoot</code>四个组件实现快速布局。</p>

    </div>
  </template>
  