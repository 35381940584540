
  <template>
    <div class="nui-doc">
      <h1>Download and Use</h1>
<h2>Download</h2>
<pre class="hljs" v-pre><code>npm install @nuilibrary/nui --save 
</code></pre>
<h2>Use</h2>
<p>In the <code>main.js</code> file of the project</p>
<pre class="hljs" v-pre><code><span class="hljs-keyword">import</span> NUI <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;@nuilibrary/nui&#x27;</span>;

createApp(App).use(NUI).mount(<span class="hljs-string">&#x27;#app&#x27;</span>);
</code></pre>

    </div>
  </template>
  