
  <template>
    <div class="nui-doc">
      <h1>Dowload and Use 下载与使用</h1>
<h2>下载</h2>
<pre class="hljs" v-pre><code>npm install @nuilibrary/nui --save
</code></pre>
<h2>安装</h2>
<div class="badge badge-tip"><p class="badge-title">选择哪种安装方式</p>
<p>如果需要自定义配置（如颜色、主题等），请使用实例化安装，实例化类时传入配置参数。</p>
<p>如果仅需要默认配置，实例化安装、类安装均可。</p>
<p>使用 @vue/plugins 默认使用实例化安装方式。</p>
</div>
<div class="badge badge-warn"><p class="badge-title">全量引入和按需引入</p>
<p>目前，NUI 仅支持全量引入，按需引入功能后续上线。</p>
</div>
<h3>类安装</h3>
<p>在项目的<code>main.js</code>文件中</p>
<pre class="hljs" v-pre><code><span class="hljs-keyword">import</span> NUI <span class="hljs-keyword">from</span> <span class="hljs-string">&quot;@nuilibrary/nui&quot;</span>;
<span class="hljs-keyword">import</span> <span class="hljs-string">&quot;@nuilibrary/nui/lib/nui.css&quot;</span>;

createApp(App)
  .use(NUI)
  .mount(<span class="hljs-string">&quot;#app&quot;</span>);
</code></pre>
<h3>实例化安装</h3>
<p>在项目的<code>main.js</code>文件中</p>
<pre class="hljs" v-pre><code><span class="hljs-keyword">import</span> NUI <span class="hljs-keyword">from</span> <span class="hljs-string">&quot;@nuilibrary/nui&quot;</span>;
<span class="hljs-keyword">import</span> <span class="hljs-string">&quot;@nuilibrary/nui/lib/nui.css&quot;</span>;

<span class="hljs-keyword">const</span> nui = <span class="hljs-keyword">new</span> NUI({
  <span class="hljs-comment">// Config...</span>
});

createApp(App)
  .use(nui)
  .mount(<span class="hljs-string">&quot;#app&quot;</span>);
</code></pre>
<p>你也可以在一个单独的 <code>nui.js</code> 文件中实例化 NUI 并导出，在 <code>main.js</code> 中引入，以保持 <code>main.js</code> 的简洁。</p>
<pre class="hljs" v-pre><code><span class="hljs-comment">// plugins/nui.js</span>
<span class="hljs-keyword">import</span> NUI <span class="hljs-keyword">from</span> <span class="hljs-string">&quot;nui&quot;</span>;

<span class="hljs-keyword">export</span> <span class="hljs-keyword">default</span> <span class="hljs-keyword">new</span> NUI({
  <span class="hljs-comment">// Config...</span>
});

<span class="hljs-comment">// main.js</span>
<span class="hljs-keyword">import</span> nui <span class="hljs-keyword">from</span> <span class="hljs-string">&quot;./plugins/nui&quot;</span>;

createApp(App)
  .use(nui)
  .mount(<span class="hljs-string">&quot;#app&quot;</span>);
</code></pre>
<h2>使用</h2>
<p>现在，你可以在该项目的任意页面/组件中使用需要的组件了。</p>

    </div>
  </template>
  