
  <template>
    <div class="nui-doc">
      <h1>Card 卡片</h1>
<p>卡片是基础的容器组件，用来包裹、装饰、定位内容。</p>
<p>卡片组件除默认插槽外，还包含 4 个可选的具名插槽——</p>
<ul>
<li>title</li>
<li>subtitle</li>
<li>text</li>
<li>action</li>
</ul>
<h2>基础用法</h2>
<nui-demo>
          <template v-slot:des><p>基础用法</p></template>
          <template v-slot:show><Nuidemohcardhppppncardncardtitletitle247 /></template>
          <template v-slot:code>
        <pre class="hljs" v-pre><code><span class="hljs-tag">&lt;<span class="hljs-name">n-card</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">n-card-title</span>&gt;</span>Title<span class="hljs-tag">&lt;/<span class="hljs-name">n-card-title</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">n-card-subtitle</span>&gt;</span>Subtitle<span class="hljs-tag">&lt;/<span class="hljs-name">n-card-subtitle</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">n-card-text</span>&gt;</span>Text Content<span class="hljs-tag">&lt;/<span class="hljs-name">n-card-text</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">n-card-action</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-button</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;primary&quot;</span>&gt;</span>Submit<span class="hljs-tag">&lt;/<span class="hljs-name">n-button</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-button</span>&gt;</span>Cancel<span class="hljs-tag">&lt;/<span class="hljs-name">n-button</span>&gt;</span>
  <span class="hljs-tag">&lt;/<span class="hljs-name">n-card-action</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">n-card</span>&gt;</span>
</code></pre>
</template></nui-demo>
<h2>背景色</h2>
<p>使用<code>color</code>属性使用不同的背景色。实际上，它与类名<code>bg-color</code>效果一直，NUI 在此处提供了语法糖。</p>
<nui-demo>
          
          <template v-slot:show><Nuidemohcardhppppnrowjustifyspacebetw2057 /></template>
          <template v-slot:code>
        <pre class="hljs" v-pre><code><span class="hljs-tag">&lt;<span class="hljs-name">n-row</span> <span class="hljs-attr">justify</span>=<span class="hljs-string">&quot;space-between&quot;</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">n-col</span> <span class="hljs-attr">:sm</span>=<span class="hljs-string">&quot;20&quot;</span> <span class="hljs-attr">:md</span>=<span class="hljs-string">&quot;6&quot;</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-card</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;info&quot;</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;my-1&quot;</span>&gt;</span>
      <span class="hljs-tag">&lt;<span class="hljs-name">n-card-title</span>&gt;</span>Title<span class="hljs-tag">&lt;/<span class="hljs-name">n-card-title</span>&gt;</span>
      <span class="hljs-tag">&lt;<span class="hljs-name">n-card-subtitle</span>&gt;</span>Subtitle<span class="hljs-tag">&lt;/<span class="hljs-name">n-card-subtitle</span>&gt;</span>
      <span class="hljs-tag">&lt;<span class="hljs-name">n-card-text</span>&gt;</span>Text Content<span class="hljs-tag">&lt;/<span class="hljs-name">n-card-text</span>&gt;</span>
      <span class="hljs-tag">&lt;<span class="hljs-name">n-card-action</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">n-button</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;text-light&quot;</span>&gt;</span>Submit<span class="hljs-tag">&lt;/<span class="hljs-name">n-button</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">n-button</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;text-light&quot;</span> <span class="hljs-attr">plain</span>&gt;</span>Cancel<span class="hljs-tag">&lt;/<span class="hljs-name">n-button</span>&gt;</span>
      <span class="hljs-tag">&lt;/<span class="hljs-name">n-card-action</span>&gt;</span>
    <span class="hljs-tag">&lt;/<span class="hljs-name">n-card</span>&gt;</span>
  <span class="hljs-tag">&lt;/<span class="hljs-name">n-col</span>&gt;</span>
  
  <span class="hljs-tag">&lt;<span class="hljs-name">n-col</span> <span class="hljs-attr">:sm</span>=<span class="hljs-string">&quot;20&quot;</span> <span class="hljs-attr">:md</span>=<span class="hljs-string">&quot;6&quot;</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-card</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;success&quot;</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;my-1&quot;</span>&gt;</span>
      <span class="hljs-tag">&lt;<span class="hljs-name">n-card-title</span>&gt;</span>Title<span class="hljs-tag">&lt;/<span class="hljs-name">n-card-title</span>&gt;</span>
      <span class="hljs-tag">&lt;<span class="hljs-name">n-card-subtitle</span>&gt;</span>Subtitle<span class="hljs-tag">&lt;/<span class="hljs-name">n-card-subtitle</span>&gt;</span>
      <span class="hljs-tag">&lt;<span class="hljs-name">n-card-text</span>&gt;</span>Text Content<span class="hljs-tag">&lt;/<span class="hljs-name">n-card-text</span>&gt;</span>
      <span class="hljs-tag">&lt;<span class="hljs-name">n-card-action</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">n-button</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;text-light&quot;</span>&gt;</span>Submit<span class="hljs-tag">&lt;/<span class="hljs-name">n-button</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">n-button</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;text-light&quot;</span> <span class="hljs-attr">plain</span>&gt;</span>Cancel<span class="hljs-tag">&lt;/<span class="hljs-name">n-button</span>&gt;</span>
      <span class="hljs-tag">&lt;/<span class="hljs-name">n-card-action</span>&gt;</span>
    <span class="hljs-tag">&lt;/<span class="hljs-name">n-card</span>&gt;</span>
  <span class="hljs-tag">&lt;/<span class="hljs-name">n-col</span>&gt;</span>
  
  <span class="hljs-tag">&lt;<span class="hljs-name">n-col</span> <span class="hljs-attr">:sm</span>=<span class="hljs-string">&quot;20&quot;</span> <span class="hljs-attr">:md</span>=<span class="hljs-string">&quot;6&quot;</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-card</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;danger&quot;</span> <span class="hljs-attr">class</span>=<span class="hljs-string">&quot;my-1&quot;</span>&gt;</span>
      <span class="hljs-tag">&lt;<span class="hljs-name">n-card-title</span>&gt;</span>Title<span class="hljs-tag">&lt;/<span class="hljs-name">n-card-title</span>&gt;</span>
      <span class="hljs-tag">&lt;<span class="hljs-name">n-card-subtitle</span>&gt;</span>Subtitle<span class="hljs-tag">&lt;/<span class="hljs-name">n-card-subtitle</span>&gt;</span>
      <span class="hljs-tag">&lt;<span class="hljs-name">n-card-text</span>&gt;</span>Text Content<span class="hljs-tag">&lt;/<span class="hljs-name">n-card-text</span>&gt;</span>
      <span class="hljs-tag">&lt;<span class="hljs-name">n-card-action</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">n-button</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;text-light&quot;</span>&gt;</span>Submit<span class="hljs-tag">&lt;/<span class="hljs-name">n-button</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">n-button</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;text-light&quot;</span> <span class="hljs-attr">plain</span>&gt;</span>Cancel<span class="hljs-tag">&lt;/<span class="hljs-name">n-button</span>&gt;</span>
      <span class="hljs-tag">&lt;/<span class="hljs-name">n-card-action</span>&gt;</span>
    <span class="hljs-tag">&lt;/<span class="hljs-name">n-card</span>&gt;</span>
  <span class="hljs-tag">&lt;/<span class="hljs-name">n-col</span>&gt;</span>
  
<span class="hljs-tag">&lt;/<span class="hljs-name">n-row</span>&gt;</span>

</code></pre>
</template></nui-demo>
<h2>平坦（无阴影）</h2>
<p>卡片组件默认存在阴影样式，使用<code>flat</code>属性移除阴影样式。</p>
<nui-demo>
          
          <template v-slot:show><Nuidemohcardhppppncardflatncardtitlet9594 /></template>
          <template v-slot:code>
        <pre class="hljs" v-pre><code><span class="hljs-tag">&lt;<span class="hljs-name">n-card</span> <span class="hljs-attr">flat</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">n-card-title</span>&gt;</span>Title<span class="hljs-tag">&lt;/<span class="hljs-name">n-card-title</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">n-card-subtitle</span>&gt;</span>Subtitle<span class="hljs-tag">&lt;/<span class="hljs-name">n-card-subtitle</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">n-card-text</span>&gt;</span>Text Content<span class="hljs-tag">&lt;/<span class="hljs-name">n-card-text</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">n-card-action</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-button</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;primary&quot;</span>&gt;</span>Submit<span class="hljs-tag">&lt;/<span class="hljs-name">n-button</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-button</span>&gt;</span>Cancel<span class="hljs-tag">&lt;/<span class="hljs-name">n-button</span>&gt;</span>
  <span class="hljs-tag">&lt;/<span class="hljs-name">n-card-action</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">n-card</span>&gt;</span>
</code></pre>
</template></nui-demo>
<h2>悬浮高度</h2>
<p>使用<code>hover</code>属性，在指针悬浮时，提升海拔。</p>
<nui-demo>
          
          <template v-slot:show><Nuidemohcardhppppncardhoverncardtitle11402 /></template>
          <template v-slot:code>
        <pre class="hljs" v-pre><code><span class="hljs-tag">&lt;<span class="hljs-name">n-card</span> <span class="hljs-attr">hover</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">n-card-title</span>&gt;</span>Title<span class="hljs-tag">&lt;/<span class="hljs-name">n-card-title</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">n-card-subtitle</span>&gt;</span>Subtitle<span class="hljs-tag">&lt;/<span class="hljs-name">n-card-subtitle</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">n-card-text</span>&gt;</span>Text Content<span class="hljs-tag">&lt;/<span class="hljs-name">n-card-text</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">n-card-action</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-button</span> <span class="hljs-attr">color</span>=<span class="hljs-string">&quot;primary&quot;</span>&gt;</span>Submit<span class="hljs-tag">&lt;/<span class="hljs-name">n-button</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-button</span>&gt;</span>Cancel<span class="hljs-tag">&lt;/<span class="hljs-name">n-button</span>&gt;</span>
  <span class="hljs-tag">&lt;/<span class="hljs-name">n-card-action</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">n-card</span>&gt;</span>
</code></pre>
</template></nui-demo>
<h2>属性</h2>

    </div>
  </template>
  