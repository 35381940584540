
  <template>
    <div class="nui-doc">
      <h1>Theme and Color 主题与颜色</h1>
<h2>设计理念</h2>
<p>NUI 颜色系统设计思路是这样的：</p>
<ol>
<li>存在 N 个主题颜色库，与 1 个基本颜色库；</li>
<li>任意时刻仅有 1 个主题可使用；而基本颜色库始终可使用，且主题的切换不影响基本颜色库；</li>
<li>被激活的主题颜色库与基本颜色库拥有平等的地位；</li>
<li>主题颜色库应使用在有意义的行为或有约束规范的场景中；</li>
<li>基本颜色库应使用在恒定颜色的场景中；</li>
</ol>
<p>在下文中，若无特殊说明，颜色库指代主题颜色库与基本颜色库的集合。</p>
<p>NUI 为颜色库中的每一种颜色，以XXX算法。均生成了 5 种更亮的相似色（冠以后缀 <code>-lighten1</code> 至 <code>-lighten5</code>）， 5 种更暗的相似色（冠以后缀 <code>-darken1</code> 至 <code>-darken5</code>）。例如<code>primary</code>颜色拥有<code>primary-lighten1</code>、<code>primary-darken3</code>等相似色。</p>
<p>在下文中，若无特殊说明，颜色指代颜色库中的每一种颜色及其 10 种相似色的集合。<code>$colorName</code>指代颜色的名字。</p>
<h2>预设与更改预设</h2>
<p>NUI 预设了 2 套主题颜色库与 1 套基本颜色库。</p>
<p>主题颜色库包括颜色…</p>
<p>基本颜色库包括颜色…</p>
<p>NUI 为开发者在开发环境与生产环境中，提供以下能力。用户是否拥有下述权力应由开发者决定。</p>
<ul>
<li>新增主题</li>
<li>修改已存在的主题中颜色</li>
<li>新增基本颜色</li>
<li>修改已存在的基本颜色</li>
<li>选择是否跟随系统主题偏好</li>
<li>自定跟随主题偏好时，分别对应何种主题</li>
</ul>
<div class="badge badge-danger"><p class="badge-title"></p>
<p>因被激活的主题颜色库与基本颜色库拥有平等的地位，故基本颜色库中的颜色名，不能与主题颜色库中的颜色重名。</p>
<p>若重名，后设定的将覆盖之前。</p>
</div>
<div class="badge badge-danger"><p class="badge-title"></p>
<p>所有主题库应均拥有一致的颜色名，以保证切换不同的主题，颜色不会为空。</p>
</div>
<h3>在开发环境中</h3>
<p>通过传入 NUI 构造参数，以修改预设。</p>
<p>如下是 NUI 默认预设的构造参数——</p>
<pre class="hljs" v-pre><code>{
  <span class="hljs-attr">&quot;defaultTheme&quot;</span>: [<span class="hljs-string">&quot;light&quot;</span>, <span class="hljs-string">&quot;dark&quot;</span>],
  <span class="hljs-attr">&quot;color&quot;</span>: {
    <span class="hljs-attr">&quot;red&quot;</span>: <span class="hljs-string">&quot;#FF3D3D&quot;</span>,
    <span class="hljs-attr">&quot;orange&quot;</span>: <span class="hljs-string">&quot;#FF9E3D&quot;</span>,
    <span class="hljs-attr">&quot;yellow&quot;</span>: <span class="hljs-string">&quot;#FFFF3D&quot;</span>,
    <span class="hljs-attr">&quot;chartreuse&quot;</span>: <span class="hljs-string">&quot;#9FFF3D&quot;</span>,
    <span class="hljs-attr">&quot;green&quot;</span>: <span class="hljs-string">&quot;#3EFF3D&quot;</span>,
    <span class="hljs-attr">&quot;spring&quot;</span>: <span class="hljs-string">&quot;#3DFF9D&quot;</span>,
    <span class="hljs-attr">&quot;cyan&quot;</span>: <span class="hljs-string">&quot;#3DFEFF&quot;</span>,
    <span class="hljs-attr">&quot;sky&quot;</span>: <span class="hljs-string">&quot;#3D9DFF&quot;</span>,
    <span class="hljs-attr">&quot;blue&quot;</span>: <span class="hljs-string">&quot;#3E3DFF&quot;</span>,
    <span class="hljs-attr">&quot;purple&quot;</span>: <span class="hljs-string">&quot;#9F3DFF&quot;</span>,
    <span class="hljs-attr">&quot;magenta&quot;</span>: <span class="hljs-string">&quot;#FF3DFF&quot;</span>,
    <span class="hljs-attr">&quot;pink&quot;</span>: <span class="hljs-string">&quot;#FF3D9E&quot;</span>,
    <span class="hljs-attr">&quot;white&quot;</span>: <span class="hljs-string">&quot;#DDDDDD&quot;</span>,
    <span class="hljs-attr">&quot;black&quot;</span>: <span class="hljs-string">&quot;#333333&quot;</span>
  },
  <span class="hljs-attr">&quot;break&quot;</span>: {
    <span class="hljs-attr">&quot;xs&quot;</span>: {
      <span class="hljs-attr">&quot;type&quot;</span>: <span class="hljs-string">&quot;max-width&quot;</span>,
      <span class="hljs-attr">&quot;value&quot;</span>: <span class="hljs-string">&quot;768px&quot;</span>
    },
    <span class="hljs-attr">&quot;sm&quot;</span>: {
      <span class="hljs-attr">&quot;type&quot;</span>: <span class="hljs-string">&quot;min-width&quot;</span>,
      <span class="hljs-attr">&quot;value&quot;</span>: <span class="hljs-string">&quot;768px&quot;</span>
    },
    <span class="hljs-attr">&quot;md&quot;</span>: {
      <span class="hljs-attr">&quot;type&quot;</span>: <span class="hljs-string">&quot;min-width&quot;</span>,
      <span class="hljs-attr">&quot;value&quot;</span>: <span class="hljs-string">&quot;992px&quot;</span>
    },
    <span class="hljs-attr">&quot;ls&quot;</span>: {
      <span class="hljs-attr">&quot;type&quot;</span>: <span class="hljs-string">&quot;min-width&quot;</span>,
      <span class="hljs-attr">&quot;value&quot;</span>: <span class="hljs-string">&quot;1200px&quot;</span>
    },
    <span class="hljs-attr">&quot;xl&quot;</span>: {
      <span class="hljs-attr">&quot;type&quot;</span>: <span class="hljs-string">&quot;min-width&quot;</span>,
      <span class="hljs-attr">&quot;value&quot;</span>: <span class="hljs-string">&quot;1920px&quot;</span>
    }
  },
  <span class="hljs-attr">&quot;themes&quot;</span>: {
    <span class="hljs-attr">&quot;light&quot;</span>: {
      <span class="hljs-attr">&quot;primary&quot;</span>: <span class="hljs-string">&quot;#42b983&quot;</span>,
      <span class="hljs-attr">&quot;success&quot;</span>: <span class="hljs-string">&quot;#42b983&quot;</span>,
      <span class="hljs-attr">&quot;warning&quot;</span>: <span class="hljs-string">&quot;#e7c000&quot;</span>,
      <span class="hljs-attr">&quot;danger&quot;</span>: <span class="hljs-string">&quot;#cc0000&quot;</span>,
      <span class="hljs-attr">&quot;bg&quot;</span>: <span class="hljs-string">&quot;#ffffff&quot;</span>,
      <span class="hljs-attr">&quot;text&quot;</span>: <span class="hljs-string">&quot;#2c3e50&quot;</span>,
      <span class="hljs-attr">&quot;bg-light&quot;</span>: <span class="hljs-string">&quot;#ffffff&quot;</span>,
      <span class="hljs-attr">&quot;fg&quot;</span>: <span class="hljs-string">&quot;#f6f6f6&quot;</span>,
      <span class="hljs-attr">&quot;bg-dark&quot;</span>: <span class="hljs-string">&quot;#475050&quot;</span>,
      <span class="hljs-attr">&quot;text-light&quot;</span>: <span class="hljs-string">&quot;#ffffff&quot;</span>,
      <span class="hljs-attr">&quot;text-dark&quot;</span>: <span class="hljs-string">&quot;#2c3e50&quot;</span>
    },
    <span class="hljs-attr">&quot;dark&quot;</span>: {
      <span class="hljs-attr">&quot;primary&quot;</span>: <span class="hljs-string">&quot;#42b983&quot;</span>,
      <span class="hljs-attr">&quot;success&quot;</span>: <span class="hljs-string">&quot;#42b983&quot;</span>,
      <span class="hljs-attr">&quot;warning&quot;</span>: <span class="hljs-string">&quot;#e7c000&quot;</span>,
      <span class="hljs-attr">&quot;danger&quot;</span>: <span class="hljs-string">&quot;#cc0000&quot;</span>,
      <span class="hljs-attr">&quot;bg&quot;</span>: <span class="hljs-string">&quot;#121212&quot;</span>,
      <span class="hljs-attr">&quot;text&quot;</span>: <span class="hljs-string">&quot;#ffffff&quot;</span>,
      <span class="hljs-attr">&quot;bg-light&quot;</span>: <span class="hljs-string">&quot;#ffffff&quot;</span>,
      <span class="hljs-attr">&quot;fg&quot;</span>: <span class="hljs-string">&quot;#f6f6f6&quot;</span>,
      <span class="hljs-attr">&quot;bg-dark&quot;</span>: <span class="hljs-string">&quot;#475050&quot;</span>,
      <span class="hljs-attr">&quot;text-light&quot;</span>: <span class="hljs-string">&quot;#ffffff&quot;</span>,
      <span class="hljs-attr">&quot;text-dark&quot;</span>: <span class="hljs-string">&quot;#2c3e50&quot;</span>
    }
  }
}
</code></pre>
<p>如下是修改示例——</p>
<h3>在生产环境中</h3>
<p><n-link color="primary" v-on:click="$router.push({name:'changeOptions'})">尝试修改配置</n-link></p>
<p>$nui.getOptions()</p>
<p>$nui.setOptions()</p>
<h2>使用</h2>
<h3>通过类名的方式设定背景色和文字色</h3>
<table>
<thead>
<tr>
<th>类名</th>
<th>用途</th>
</tr>
</thead>
<tbody>
<tr>
<td><code>bg-colorname</code></td>
<td>背景颜色</td>
</tr>
<tr>
<td><code>text-colorname</code></td>
<td>文本颜色</td>
</tr>
</tbody>
</table>
<nui-demo>
          <template v-slot:des><p>使用`bg-color`类名设定元素的背景颜色</p></template>
          <template v-slot:show><Nuidemohthemeandcnrowvforcolornameind7621 /></template>
          <template v-slot:code>
        <pre class="hljs" v-pre><code><span class="hljs-tag">&lt;<span class="hljs-name">n-row</span> <span class="hljs-attr">v-for</span>=<span class="hljs-string">&quot;(colorName,index) in colorNameSet&quot;</span> <span class="hljs-attr">:key</span>=<span class="hljs-string">&quot;index&quot;</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-col</span>
      <span class="hljs-attr">v-for</span>=<span class="hljs-string">&quot;i in 5&quot;</span>
      <span class="hljs-attr">:key</span>=<span class="hljs-string">&quot;i&quot;</span>
      <span class="hljs-attr">:span</span>=<span class="hljs-string">&quot;4&quot;</span>
      <span class="hljs-attr">:class</span>=<span class="hljs-string">&quot;`bg-${colorName}-lighten${6-i}`&quot;</span>
           &gt;</span>{{`bg-${colorName}-lighten${6-i}`}}<span class="hljs-tag">&lt;/<span class="hljs-name">n-col</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-col</span>
      <span class="hljs-attr">:span</span>=<span class="hljs-string">&quot;20&quot;</span>
      <span class="hljs-attr">:class</span>=<span class="hljs-string">&quot;`bg-${colorName}`&quot;</span>
           &gt;</span>{{`bg-${colorName}`}}<span class="hljs-tag">&lt;/<span class="hljs-name">n-col</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">n-col</span>
      <span class="hljs-attr">v-for</span>=<span class="hljs-string">&quot;i in 5&quot;</span>
      <span class="hljs-attr">:key</span>=<span class="hljs-string">&quot;i&quot;</span>
      <span class="hljs-attr">:span</span>=<span class="hljs-string">&quot;4&quot;</span>
      <span class="hljs-attr">:class</span>=<span class="hljs-string">&quot;`bg-${colorName}-darken${i}`&quot;</span>
           &gt;</span>{{`bg-${colorName}-darken${i}`}}<span class="hljs-tag">&lt;/<span class="hljs-name">n-col</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">n-row</span>&gt;</span>

<span class="hljs-tag">&lt;<span class="hljs-name">script</span>&gt;</span><span class="javascript">
  <span class="hljs-keyword">export</span> <span class="hljs-keyword">default</span> {
    <span class="hljs-function"><span class="hljs-title">data</span>(<span class="hljs-params"></span>)</span> {
      <span class="hljs-keyword">return</span> {
        <span class="hljs-attr">colorNameSet</span>: [
          <span class="hljs-string">&#x27;primary&#x27;</span>,
          <span class="hljs-string">&#x27;success&#x27;</span>,
          <span class="hljs-string">&#x27;warning&#x27;</span>,
          <span class="hljs-string">&#x27;danger&#x27;</span>,
          <span class="hljs-string">&#x27;info&#x27;</span>,
          <span class="hljs-string">&#x27;text&#x27;</span>,
          <span class="hljs-string">&#x27;bg&#x27;</span>,
          <span class="hljs-string">&#x27;red&#x27;</span>,
          <span class="hljs-string">&#x27;orange&#x27;</span>,
          <span class="hljs-string">&#x27;yellow&#x27;</span>,
          <span class="hljs-string">&#x27;chartreuse&#x27;</span>,
          <span class="hljs-string">&#x27;green&#x27;</span>,
          <span class="hljs-string">&#x27;spring&#x27;</span>,
          <span class="hljs-string">&#x27;cyan&#x27;</span>,
          <span class="hljs-string">&#x27;sky&#x27;</span>,
          <span class="hljs-string">&#x27;blue&#x27;</span>,
          <span class="hljs-string">&#x27;purple&#x27;</span>,
          <span class="hljs-string">&#x27;magenta&#x27;</span>,
          <span class="hljs-string">&#x27;pink&#x27;</span>,
          <span class="hljs-string">&#x27;white&#x27;</span>,
          <span class="hljs-string">&#x27;black&#x27;</span>
        ],
      };
    },
  };
</span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>

<span class="hljs-tag">&lt;<span class="hljs-name">style</span> <span class="hljs-attr">scoped</span>&gt;</span><span class="css">
  <span class="hljs-selector-class">.n-col</span> {
    <span class="hljs-attribute">height</span>: <span class="hljs-number">50px</span>;
  }
</span><span class="hljs-tag">&lt;/<span class="hljs-name">style</span>&gt;</span>
</code></pre>
</template></nui-demo>
<h3>在 CSS 中使用自定义属性</h3>
<p>NUI 在 CSS 中的自定义属性为 <code>--nui-color-$colorName</code>，使用时，应为<code>var(--nui-color-$colorName)</code>。例如——</p>
<pre class="hljs" v-pre><code><span class="hljs-selector-tag">h1</span><span class="hljs-selector-class">.hero</span>{
  <span class="hljs-attribute">background-color</span>: <span class="hljs-built_in">var</span>(--nui-color-bg-darken2);
  <span class="hljs-attribute">color</span>: <span class="hljs-built_in">var</span>(--nui-color-text);
  <span class="hljs-attribute">border-color</span>: <span class="hljs-built_in">var</span>(--nui-color-green);
}
</code></pre>

    </div>
  </template>
  